import React, { Fragment, useState, useEffect } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { saasTheme } from 'common/src/theme/saas';
import { ResetCSS } from 'common/src/assets/css/style';
import { GlobalStyle, ContentWrapper } from '../containers/Saas/saas.style';
import Navbar from '../containers/Saas/Navbar';
import BannerSection from '../containers/Saas/BannerSection/newIndex';
import SequenceSection from '../containers/Saas/VisitorSection';
import FeatureSection from '../containers/Saas/ServiceSection';
import Footer from '../containers/Saas/Footer';
import CallToActionBottom from '../containers/Saas/TrialSection';
//import StructureSection from '../containers/Saas/structureSection';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import SEO from '../components/seo';
//import News from '../containers/Saas/News';
//import StructureSectionSmall from '../containers/Saas/structureSectionSmall';
import Layout from '../components/layout';
import PillarSection from '../containers/Saas/pillarSection';
import BoosterSection from '../containers/Saas/boosters';
import ExtensionView from '../containers/Saas/extensionPage';
import ModalForm from '../containers/Saas/contactModal';

function useWindowSize() {
  const isClient = typeof window === 'object';

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);
  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}

export default () => {
  if (typeof window === `undefined`) {
    return <></>;
  }

  useEffect(() => {
    if (window) {
      var hash = window.location.hash;
      if (hash) {
        setTimeout(() => {
          hash = hash.slice(1, hash.length);
          var element = document.getElementById(hash);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }, 1500);
      }
    }
  }, []);
  const [openDemoModal, setOpenDemoModal] = useState(false);

  function handleOpenDemoModal() {
    setOpenDemoModal(true);
  }
  return (
    <ThemeProvider theme={saasTheme}>
      <Fragment>
        <SEO
          title="An organized and data-driven approach to sales development"
          description="SalesOcean provides an Organized and Data-driven approach to sales development. Drive efficiency to your pipeline and join the sea of opportunities with SalesOcean."
        />
        <ResetCSS />
        <GlobalStyle />
        <Layout>
          <ContentWrapper>
            <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
              <DrawerProvider>
                <Navbar />
              </DrawerProvider>
            </Sticky>
            <BannerSection handleOpenDemoModal={handleOpenDemoModal} />
            <PillarSection />
            <FeatureSection />
            <BoosterSection handleOpenDemoModal={handleOpenDemoModal} />
            <SequenceSection handleOpenDemoModal={handleOpenDemoModal} />
            <ExtensionView handleOpenDemoModal={handleOpenDemoModal} />
            <CallToActionBottom />
            <Footer />
            <ModalForm open={openDemoModal} setOpen={setOpenDemoModal} />
          </ContentWrapper>
        </Layout>
      </Fragment>
    </ThemeProvider>
  );
};
