import styled from 'styled-components';

export const LogoSlideWrapper = styled.div`
  -webkit-animation: rotation 10s infinite linear;
  position: absolute;
  top: 30%;
  left: 0%;

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export const LogoSlideWrapper1 = styled.div`
  -webkit-animation: rotation 5s infinite linear;
  position: absolute;
  top: 20%;
  left: 10%;
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export const LogoSlideWrapper2 = styled.div`
  -webkit-animation: rotation 7s infinite linear;
  position: absolute;

  top: 25%;
  left: 5%;
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
