import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Image from 'common/src/components/Image';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Fade from 'react-reveal/Fade';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import BannerWrapper, {
  BannerObject,
  SmallImgDisplay,
  BackgroundShadow1,
  BackgroundShadow2,
} from './newBannerSection.style';
import SmallDashboard from 'common/src/assets/image/saas/banner/dashboardBanner.png';
import LogoSlides from '../logoSlides';
import {
  TopBootstrap,
  BottomBootstrap,
} from 'common/src/components/bootstrapView/index.style';

const BannerSection = ({
  row,
  col,
  title,
  description,
  outlineBtnStyle,
  containedBtnStyle,
  handleOpenDemoModal,
}) => {
  const ButtonGroup = () => (
    <Fragment>
      <Button
        title={'GET DEMO'}
        className="outlined"
        variant="outlined"
        {...containedBtnStyle}
        style={{ marginLeft: '0' }}
        onClick={handleOpenDemoModal}
      />
      <Button
        className="outlined"
        title="EXPLORE FEATURES"
        variant="outlined"
        onClick={() => window.open('/features', '_self')}
        {...outlineBtnStyle}
      />
    </Fragment>
  );

  return (
    <BannerWrapper id="banner_section">
      <BackgroundShadow1 />
      <BackgroundShadow2 />

      <Container>
        <TopBootstrap>
          <div style={{ width: '100%', height: '5rem' }}>
            <Fade right>
              <LogoSlides
                style={{ width: '13rem' }}
                rootStyle={{ position: 'absolute', right: '50%', top: '24%' }}
              />
            </Fade>
          </div>
        </TopBootstrap>
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            {/* <DiscountLabel>
              <Text
                content="Get early access through the "
                {...discountText}
                onClick={() => window.open('/contact#beta', '_self')}
              />
              <Text
                content="BETA TRIAL"
                {...discountAmount}
                onClick={() => window.open('/contact#beta', '_self')}
              />
            </DiscountLabel> */}

            <FeatureBlock
              title={
                <Heading
                  /*content="More than
                    just a
                    Sales Engagement Platform."*/
                  content={
                    <>
                      Accelerate on
                      <br />
                      Lead Generation
                    </>
                  }
                  {...title}
                />
              }
              description={
                <Text
                  content={
                    <span>
                      Get{' '}
                      <span style={{ color: '#52bd95', fontWeight: 600 }}>
                        Organised
                      </span>
                      ,
                      <span style={{ color: '#52bd95', fontWeight: 600 }}>
                        {' '}
                        Automated{' '}
                      </span>
                      and{' '}
                      <span style={{ color: '#52bd95', fontWeight: 600 }}>
                        {' '}
                        Analyse{' '}
                      </span>{' '}
                      Account-based marketing activities. Drive efficiency to
                      your pipeline and make sure reps don't miss any
                      opportunity!
                    </span>
                  }
                  icon={
                    ''
                    /*<img
                      style={{ height: '24px', width: '24px' }}
                      src={RocketIcon}
                      alt="rocket image"
                    />*/
                  }
                  {...description}
                />
              }
              button={<ButtonGroup />}
            />
          </Box>
        </Box>
      </Container>
      <BottomBootstrap>
        <BannerObject>
          <Fade right>
            <LogoSlides />
          </Fade>
        </BannerObject>
      </BottomBootstrap>
    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  title: PropTypes.object,
  btnStyle: PropTypes.object,
  description: PropTypes.object,
  contentStyle: PropTypes.object,
  discountText: PropTypes.object,
  discountAmount: PropTypes.object,
  outlineBtnStyle: PropTypes.object,
  dashboard: PropTypes.object,
  containedBtnStyle: PropTypes.object,
};

BannerSection.defaultProps = {
  dashboard: {
    width: '90%',
    float: 'right',
    borderTopLeftRadius: '5px',
    borderBottomLeftRadius: '5px',
    boxShadow: '0px 10px 5px rgba(0,0,0,0.6)',
    MozBoxShadow: '0px 10px 5px rgba(0,0,0,0.6)',
    WebkitBoxShadow: '11px 5px 6px 4px rgba(0,0,0,0.6)',
    OBoxShadow: '0px 10px 5px rgba(0,0,0,0.6)',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: [1, '70%', '50%', '45%'],
  },
  title: {
    fontSize: ['30px', '34px', '30px', '40px', '50px'],
    fontWeight: '700',
    color: '#ffffff',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px'],
    lineHeight: '1.5',
    as: 'h1',
  },
  description: {
    fontSize: '21px',
    color: '#b1afaf',
    lineHeight: '1.7',
    mb: '0',
  },
  containedBtnStyle: {
    minWidth: ['120px', '120px', '120px', '156px'],
    fontSize: ['13px', '14px'],
    fontWeight: '500',
    colors: 'primaryWithBg',
  },
  btnStyle: {
    minWidth: ['120px', '120px', '120px', '156px'],
    fontSize: ['13px', '14px'],
    fontWeight: '500',
    colors: 'primaryWithBg',
  },
  outlineBtnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    color: '#52bd95',
    ml: ['10px', '18px'],
    type: 'button',
    colors: 'third',
    style: {
      borderColor: '#52bd95',
    },
  },
  discountAmount: {
    fontSize: '14px',
    color: '#eb4d4b',
    mb: 0,
    as: 'span',
    mr: '0.4em',
    fontWeight: 700,
  },
  discountText: {
    fontSize: ['13px', '14px'],
    color: '#2a313c',
    mb: 0,
    as: 'span',
    fontWeight: 500,
  },
};

export default BannerSection;
