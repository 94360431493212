import styled from 'styled-components';
import { themeGet } from 'styled-system';
//import BannerBG from 'common/src/assets/image/saas/saas-banner.jpg';
import BannerBGG from 'common/src/assets/image/saas/banner-texture.png';

const BannerWrapper = styled.section`
  padding-top: 250px;
  padding-bottom: 200px;
  //background-image: url(${BannerBGG});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: #2a313c;
  //min-height: 100vh;
  position: relative;
  overflow: hidden;
  @media (max-width: 990px) {
    padding-bottom: 60px;
    min-height: auto;
  }
  @media (max-width: 767px) {
    padding-top: 130px;
    padding-bottom: 20px;
    min-height: auto;
  }

  @media only screen and (max-width: 480px) {
   // background: none;
  }

  .particle {
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    @media (max-width: 767px) {
      display: none;
    }
    @media only screen and (max-width: 480px) {
      width: 100%;
    }
  }

  .row {
    position: relative;
    z-index: 1;
  }

  .button__wrapper {
    margin-top: 40px;
    @media (max-width: 767px) {
      margin-bottom: 30px;
    }
    .reusecore__button {
      &.outlined {
        border-color: rgba(82, 104, 219, 0.2);
      }
    }
  }
`;

const SmallImgDisplay = styled.div`
  display: none;
  margin-bottom: 2rem;
  margin-top: 1rem;
  @media (max-width: 767px) {
    display: block;
  }
`;

const BannerObject = styled.div`
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  @media (min-width: 1700px) {
    padding-top: 5rem;
  }
  @media (max-width: 767px) {
    display: none;
  }
  .react-reveal {
    display: contents;
  }
  .objectWrapper {
    margin-left: auto;
    position: relative;
    .dashboardWrapper {
      position: absolute;
      top: 6%;
      right: 0;
      .chatObject {
        position: absolute;
        top: 20px;
        left: 120px;
      }
    }
  }
`;

export const BackgroundShadow1 = styled.div`
  position: absolute !important;
  top: 30rem;
  right: 5rem;
  width: 125rem;
  z-index: 0;
  height: 89rem;
  background: linear-gradient(
    230.26deg,
    rgb(47 57 101 / 0%) 14.93%,
    rgba(38, 46, 79, 0.5) 25.01%,
    rgb(91 109 220 / 5%) 42.49%
  );
  -webkit-transform: rotate(-33deg);
  -ms-transform: rotate(-33deg);
  transform: rotate(190deg);
`;

export const BackgroundShadow2 = styled.div`
  position: absolute !important;
  top: 31rem;
  right: 6rem;
  width: 130rem;
  z-index: 0;
  height: 89rem;
  background: linear-gradient(
    230.26deg,
    rgb(47 57 101 / 0%) 14.93%,
    rgba(38, 46, 79, 0.5) 25.01%,
    rgb(91 109 220 / 5%) 42.49%
  );
  -webkit-transform: rotate(-33deg);
  -ms-transform: rotate(-33deg);
  transform: rotate(190deg);
`;

const DiscountLabel = styled.div`
  display: inline-block;
  border-radius: 4em;
  border: 1px solid ${themeGet('colors.lightBorder', '#f1f4f6')};
  padding: 7px 25px;
  box-shadow: 0px 7px 25px 0px rgba(22, 53, 76, 0.05);
  margin-bottom: 15px;
  margin-top: -15px;
  background-color: ${themeGet('colors.white', '#ffffff')};
  @media (max-width: 767px) {
    padding: 7px 15px;
  }
  cursor: pointer;
`;

export { DiscountLabel, BannerObject, SmallImgDisplay };

export default BannerWrapper;
