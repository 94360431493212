import React from 'react';
import {
  LogoSlideWrapper,
  LogoSlideWrapper1,
  LogoSlideWrapper2,
} from './logoSlide.style';
import LogoSlide from 'common/src/assets/image/saas/logo_slide.png';

const LogoSlides = ({ style = {}, rootStyle = {} }) => {
  return (
    <div style={rootStyle}>
      <LogoSlideWrapper>
        <img src={LogoSlide} style={style} />
      </LogoSlideWrapper>
      <LogoSlideWrapper1>
        <img src={LogoSlide} style={style} />
      </LogoSlideWrapper1>
      <LogoSlideWrapper2>
        <img src={LogoSlide} style={style} />
      </LogoSlideWrapper2>
    </div>
  );
};

export default LogoSlides;
